.loadingContainer {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
}

.loadingContainer img {
    width: 50px;
    height: 50px;
    opacity: 0.5;
}